import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Layout } from '@components';
import PropTypes from 'prop-types';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

const PublicLearning = ({ data, location }) => {
  const StyledMain = styled.main``;

  const { t } = useTranslation();

  const { posts } = data;
  return (
    <Layout location={location}>
      <StyledMain>
        <h1>{t('posts', { ns: 'publiLearning' })}</h1>
        <p dangerouslySetInnerHTML={{ __html: t('intro', { ns: 'publiLearning' }) }} />
        <p dangerouslySetInnerHTML={{ __html: t('final', { ns: 'publiLearning' }) }} />
        <ul>
          {posts.edges.map(({ node }, index) => (
            <li key={index}>
              <Link to={node.frontmatter.slug}>{node.frontmatter.title}</Link>
            </li>
          ))}
        </ul>
      </StyledMain>
    </Layout>
  );
};

export default PublicLearning;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["publicLearning", "navLinks"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { frontmatter: { date: DESC } }
    ) {
      edges {
        node {
          frontmatter {
            title
            draft
            date
            slug
          }
        }
      }
    }
  }
`;

PublicLearning.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
