import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

// eslint-disable-next-line no-unused-vars
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';
import { useI18next } from 'gatsby-plugin-react-i18next';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location, data }) => {
  // prover o idioma via pageContext no createPage()
  const { changeLanguage } = useI18next();

  const isSSR = typeof window === 'undefined';

  const userLng = isSSR ? 'en' : navigator.language.split('-')[0];

  useEffect(() => {
    changeLanguage(userLng);
  }, []);

  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        <Hero />
        <About />
        <Jobs jobs={data.jobs} />
        <Featured featured={data.featured} />
        {/* <Projects /> */}
        <Contact />
      </StyledMainContainer>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["hero", "about", "navLinks", "contact", "jobs"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    jobs: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/jobs/" }
        frontmatter: { lng: { eq: $language } }
      }
      sort: { frontmatter: { date: DESC } }
    ) {
      edges {
        node {
          frontmatter {
            title
            company
            location
            range
            url
          }
          html
        }
      }
    }

    featured: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/featured/" }
        frontmatter: { lng: { eq: $language } }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            cover {
              childImageSharp {
                gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            tech
            github
            external
          }
          html
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object,
};
export default IndexPage;
