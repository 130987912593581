import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import image from '../images/romantic-bg.jpg';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');
  
  html, body, main {
    margin: 0;
    padding: 0;
  }

`;

// eslint-disable-next-line no-unused-vars

const StyledMainContainer = styled.main`
  font-family: 'Caveat', cursive;
  height: 100vh;
  background-image: url(${image});
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledP = styled.p`
  font-size: 1.6rem;
  line-height: 1.8;
  text-align: center;
  width: 46ch;
  border: 1px black solid;
  background-color: rgba(255, 255, 0, 0.15);
  filter: invert();
  border-radius: 4px;
`;

const Andy = () => {
  return (
    <StyledMainContainer>
      <GlobalStyle />
      <StyledP>
        Amor você tem razão, <br />
        uma vida sem sentido não leva a nada. <br />
        Eu quero viver, amar e construir com meu mozão. <br />
        Andressa, você aceita ser minha namorada?
      </StyledP>
    </StyledMainContainer>
  );
};

Andy.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object,
};
export default Andy;
